import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Render Yotpo review in a lightbox like modal with split view, photo gallery on left and review
 * content on the right
 */
class LightBox extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-idx',
      'data-media',
      'data-mode',
      'data-show'
    ];
  }

  readonly dataset!: {
    /**
     * Defines index of image to be displayed from the customer review image urls list.
     */
    idx: string;

    /**
     * Comma separated list of customer review image urls.
     */
    media: string;

    /**
     * In the `standalone` mode the light box allows to display a single review.
     * If (and only if) the review has more than one picture the user can switch
     * the currently displayed one by clicking the buttons 'next' / 'prev' or swiping.
     * Clicking 'next' on the last picture will display the first one again.
     *
     * In the `managed` mode the light box emits a `LightboxNavigationEvent`
     * every time the user wants to go back on the first picture or next on
     * last one. Managed mode will also display the arrows and handle navigation
     * even if there's only a single picture for a given review.
     * This allows to switch the review in the parent element by handling
     * `LightboxNavigationEvent`s
     */
    mode: 'managed' | 'standalone';

    /**
     * Setting this to any value different than the current one will open the modal.
     */
    show: string;
  };

  public shadowRoot!: ShadowRoot;
  private onCloseClickBound = this.onCloseClick.bind(this);
  private onKeyboardEventBound = this.onKeyboardEvent.bind(this);
  private onPrevImageBound = this.onPrevClick.bind(this);
  private onNextImageBound = this.onNextClick.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const closeButton = this.shadowRoot.querySelector('.close');
    closeButton?.addEventListener('click', this.onCloseClickBound);

    const lightboxContainer = this.shadowRoot.querySelector('.container');
    lightboxContainer.classList.add('sliders');

    const prev = this.shadowRoot.querySelector<HTMLElement>('.prev');
    prev.addEventListener('click', this.onPrevImageBound);
    prev.addEventListener('keydown', this.onPrevImageBound);
    prev.addEventListener('keyup', this.onPrevImageBound);

    const next = this.shadowRoot.querySelector<HTMLElement>('.next');
    next.addEventListener('click', this.onNextImageBound);
    next.addEventListener('keydown', this.onNextImageBound);
    next.addEventListener('keyup', this.onNextImageBound);

    this.addEventListener('keydown', this.onKeyboardEventBound);
    this.addEventListener('keyup', this.onKeyboardEventBound);

    this.render();
  }

  public disconnectedCallback() {
    const closeButton = this.shadowRoot.querySelector('.close');
    closeButton?.removeEventListener('click', this.onCloseClickBound);

    const prev = this.shadowRoot.querySelector('.prev');
    prev?.removeEventListener('click', this.onPrevImageBound);
    prev?.removeEventListener('keyup', this.onPrevImageBound);
    prev?.removeEventListener('keydown', this.onPrevImageBound);

    const next = this.shadowRoot.querySelector('.next');
    next?.removeEventListener('click', this.onNextImageBound);
    next?.removeEventListener('keyup', this.onNextImageBound);
    next?.removeEventListener('keydown', this.onNextImageBound);

    this.removeEventListener('keydown', this.onKeyboardEventBound);
    this.removeEventListener('keyup', this.onKeyboardEventBound);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.isConnected && newValue !== oldValue) {
      this.render();

      if (name === 'data-show' && newValue) {
        this.openModal();
      }
    }
  }

  private getUrls() {
    const urls = this.dataset.media
      ?.replace(/\s/g, '')
      .split(',')
      .map(path => path.replace('square.jpg', 'original.jpg'));
    return urls || [];
  }

  private onPrevClick(event?: Event) {
    if (event instanceof KeyboardEvent) {
      if (['Enter', 'Space'].includes(event.code)) {
        event.preventDefault();
      } else {
        return;
      }

      if (event.type === 'keydown') {
        return;
      }
    }

    const urls = this.getUrls();
    const index = parseInt(this.dataset.idx, 10);

    if (this.dataset.mode === 'managed' && index - 1 < 0) {
      const navEvent = new CustomEvent<LightboxNavigatedEvent['detail']>('light-box-navigated', {
        composed: true,
        detail: { action: 'prev' }
      });
      this.dispatchEvent(navEvent);
      return;
    }

    if (urls.length === 0) {
      return;
    }

    if (index <= 0) {
      this.dataset.idx = (urls.length - 1).toString();
    } else if (index > 0) {
      this.dataset.idx = (index - 1).toString();
    }
    // render is automatically called when `idx` changes
  }

  private onNextClick(event?: Event) {
    if (event instanceof KeyboardEvent) {
      if (['Enter', 'Space'].includes(event.code)) {
        event.preventDefault();
      } else {
        return;
      }

      if (event.type === 'keydown') {
        return;
      }
    }

    const urls = this.getUrls();
    const index = parseInt(this.dataset.idx, 10);

    if (this.dataset.mode === 'managed' && index + 1 >= urls.length) {
      const navEvent = new CustomEvent<LightboxNavigatedEvent['detail']>('light-box-navigated', {
        composed: true,
        detail: { action: 'next' }
      });
      this.dispatchEvent(navEvent);
      return;
    }

    if (urls.length === 0) {
      return;
    }

    if (index + 1 >= urls.length) {
      this.dataset.idx = '0';
    } else {
      this.dataset.idx = (index + 1).toString();
    }
    // render is automatically called when `idx` changes
  }

  private onCloseClick(event: Event) {
    if (event instanceof KeyboardEvent) {
      if (['Enter', 'Space'].includes(event.code)) {
        event.preventDefault();
      } else {
        return;
      }

      if (event.type === 'keydown') {
        return;
      }
    }

    this.closeModal();
  }

  private onKeyboardEvent(event: KeyboardEvent) {
    if (event.type === 'keydown') {
      return;
    }

    if (event.code === 'Escape') {
      this.closeModal();
    } else if (event.code === 'ArrowLeft') {
      this.onPrevClick();
    } else if (event.code === 'ArrowRight') {
      this.onNextClick();
    }
  }

  private openModal() {
    const dialog = this.shadowRoot.querySelector<HTMLElement>('[role="dialog"]');
    dialog.removeAttribute('inert');
    dialog.classList.add('visible');

    type Detail = WindowEventMap['modal-show-requested']['detail'];
    const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
      { detail: { element: this } }
    );
    dispatchEvent(modalShowEvent);

    const closeButton = this.shadowRoot.querySelector<HTMLElement>('.close');
    closeButton.focus();

    addEventListener('shroud-clicked', this.onCloseClickBound);
  }

  private closeModal() {
    const dialog = this.shadowRoot.querySelector<HTMLElement>('[role="dialog"]');
    dialog.classList.remove('visible');
    dialog.setAttribute('inert', '');

    removeEventListener('shroud-clicked', this.onCloseClickBound);

    type ModalDetail = WindowEventMap['modal-close-requested']['detail'];
    const modalCloseEvent = new CustomEvent<ModalDetail>('modal-close-requested',
      { detail: { element: this } }
    );
    dispatchEvent(modalCloseEvent);
  }

  private render() {
    const urls = this.getUrls();

    // On every render, media may have changed. Whenever there are less than two images, we want to
    // ensure that the carousel arrows are hidden. Whenever there are two or more images, we want
    // to ensure that the carousel arrows are visible.

    const prev = this.shadowRoot.querySelector('.prev');
    const next = this.shadowRoot.querySelector('.next');

    if (this.dataset.mode === 'managed' || urls.length > 1) {
      prev.classList.remove('hidden');
      next.classList.remove('hidden');
    } else {
      prev.classList.add('hidden');
      next.classList.add('hidden');
    }

    // Update the background image. On every render, the urls may have changed. If there is no url
    // or the index is invalid, remove the background image. Otherwise, render the current image.

    const imageContainer = this.shadowRoot.querySelector<HTMLImageElement>('.image');

    if (urls.length === 0) {
      imageContainer.style.backgroundImage = '';
      return;
    }

    const index = parseInt(this.dataset.idx, 10);
    if (!Number.isInteger(index)) {
      imageContainer.style.backgroundImage = '';
      return;
    }

    if (index < 0 || index >= urls.length) {
      imageContainer.style.backgroundImage = '';
      return;
    }

    imageContainer.style.backgroundImage = `url('${urls[index]}')`;
  }
}

/**
 * Fired when a user clicks next / prev buttons in a lightbox
 */
type LightboxNavigatedEvent = CustomEvent<{
  action: 'next' | 'prev';
}>;

declare global {
  interface HTMLElementTagNameMap {
    'light-box': LightBox;
  }

  interface WindowEventMap {
    'light-box-navigated': LightboxNavigatedEvent;
  }
}

if (!customElements.get('light-box')) {
  customElements.define('light-box', LightBox);
}
